<lib-modal [show_modal]="statusModalVisible" (closeModal)="closeStatusModal()" btn_label_primary="Save"
	btn_color_primary="primary" btn_label_secondary="Cancel" (clickSecondary)="closeStatusModal()"
	(clickPrimary)="confirm()" [close_icon]='false' [body]="modalContent">
	<div class="input-wrapper-modal">
		<div class="top-header">
			<h4 class="b-font-demibold title-margin">Work Status</h4>
			<div class="b-light b-Eh4">{{worker.statuses?.flowDescription}}</div>
			<div class="aligned-flex space-between">
				<div>
					<div *ngIf="worker.profile?.last_worked_shift?.length > 0 && worker.profile?.last_worked_shift[0]?.shift?.end_at"
						class="b-Eh4">Last Worked Shift: <span
							class="b-font-demibold">{{worker.profile?.last_worked_shift[0]?.shift?.end_at |
							dateFormat:'DD/MM/YY' : 'Europe/London'}}</span></div>
				</div>
				<div *ngIf="worker.statuses?.daysToTopUp !==null" class="text-right">
					<div class="b-Eh4"><span [ngClass]="topUpColors()"
							class="b-font-demibold">{{worker.statuses?.daysToTopUp}}</span> Days</div>
					<div *ngIf="worker.statuses?.daysToTopUp > 0" class="b-Eh5 b-light">Top up needed in</div>
					<div *ngIf="worker.statuses?.daysToTopUp <= 0" class="b-Eh5 danger">Top up is required</div>
				</div>
			</div>

		</div>

		<nz-skeleton *ngIf="!worker" class="nz-cites-loading" [nzParagraph]="{ rows: 2 }" [nzTitle]="false"
			[nzActive]="true">
		</nz-skeleton>
		<div *ngIf="worker">
			<div class="worker-details">
				<nz-avatar [nzText]='worker.details?.name[0] | uppercase' [nzSrc]="worker.details?.avatar"
					nzSize="small">
				</nz-avatar>
				<div class="details">
					<h5 class="b-font-demibold">{{worker.details?.name}} {{worker.details?.surname}}</h5>
					<p>{{worker.details?.phone}}</p>
					<p class="b-Eh4 b-light">{{worker.details?.email}}</p>
				</div>
				<div class="tax-info">
					<div class="tax-info-item">
						<p>NI Nr: <span class="b-light">{{worker.profile?.ni_number ?? 'N/A' | uppercase}}</span></p>

					</div>
					<div class="tax-info-item">
						<p>Payroll Nr: <span class="b-light">{{worker.taxInformation?.payrollNo ?? 'N/A'}}</span></p>

					</div>
					<div class="tax-info-item">
						<p nz-tooltip [nzTooltipTrigger]="!worker.taxInformation?.employeeNumber ? 'hover': null"
							nzTooltipTitle='Unable to work without an Employee Number.'
							[ngClass]="!worker.taxInformation?.employeeNumber ? 'b-danger':''">Employee Nr: <span
								class="b-light">{{worker.taxInformation?.employeeNumber ?? 'N/A'}}</span>
						</p>
					</div>
					<div class="tax-info-item">
						<p>Has Bank: <span class="b-light">{{worker.profile?.has_bank ? 'Yes' : 'N/A'}}</span></p>
					</div>

				</div>
			</div>
			<div *ngIf="renderDone" class="clearance-wrapper">
				<div class="min-height-div">
					<div *ngFor="let workerStatus of workStatusesData" class="worker-clearance-item">
						<div>
							<h4>{{workerStatus.title}}</h4>
							<p class="b-light">{{workerStatus.subtitle}}</p>
							<p class="b-font-demibold"><span class="b-light icon-calendar" nz-icon nzType="calendar"
									nzTheme="outline"></span> {{workerStatus.effectiveDate ? (workerStatus.effectiveDate
								| dateFormat:'DD/MM/YY' : 'Europe/London') : 'N/A'}}</p>

						</div>
						<app-list-drop-down (selectedItem)="selectSubmissionStatus(workerStatus, $event)"
							element_width="200px" [status_param]="workerStatus.statusParam"
							[data]="workerStatus.dropDownData">
						</app-list-drop-down>
					</div>
				</div>
				<div class="note">
					<p>Soft Apply Gate is currently 
						<span *ngIf="worker.statuses.isSoftApplyGateOpen" class="b-font-demibold b-primary">On</span>
						<span *ngIf="!worker.statuses.isSoftApplyGateOpen" class="b-font-demibold">Off</span>
					</p>
					<p class="b-light">The worker must have either Full or Limited Screening and all other statuses must
						be Accepted to apply for shifts if apply gate is closed.</p>

				</div>
			</div>
		</div>
	</div>
</lib-modal>