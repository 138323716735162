<div class="jobs-wrapper">
	<div [@jobExpand] *ngIf="job" id='grid-background' class="grid">
		<lib-no-search-data *ngIf="job.shifts_count == 0 && can_edit_shifts && !job.site.source"
			img_src="assets/icons/desert-island.svg" title='It’s empty in here…'
			body='Tap the button below to start adding shifts onto this job.' button_name='Add shifts'
			(onButtonClick)="addShifts()">
		</lib-no-search-data>
		<lib-no-search-data *ngIf="checkShifts() && job.shifts_count > 0 || job.shifts_count == 0 && !can_edit_shifts"
			img_src='assets/icons/magnifying-glass-tilted-left.svg' title='No Results Found' body='Could not find any matches. Check your spelling and
    try again or click the button below to clear the filters.' button_name='Clear Filters' button_class='outline'
			(onButtonClick)="filters.clear_site_filters()">
		</lib-no-search-data>
		<div [ngClass]="selected_applications?.length > 0 ? 'grid-bottom' : ''"
			*ngIf="!checkShifts() && job.shifts_count > 0">

			<div class="header-wrapper">
				<div class='shifts-header-area'>
					<div *ngIf="adminUser && workersBlocked && workersBlocked.length > 0 "
						class="button-container floating-button">
						<app-clib-button label="Blocked workers" (pressAction)="viewBlocked()" type="outline"
							color="dark"></app-clib-button>
					</div>
					<div *ngIf="!adminUser" class="floating-button">
						<app-static-banner key="bestMatch" title="Best match Workers" body="Our algorithm ranks workers based on a number of factors; including
						whether they've worked for you before and how likely they are to be who you're looking for.
						Please feel free to give us feedback on these results!"></app-static-banner>
					</div>


					<div class="shifts-header" [ngClass]="can_edit_shifts && filters.active_toggle ? '' : 'no-edit'">

						<div class="edit-shift-area">
							<div class="spacer-header" *ngIf="!job.site.source && can_edit_shifts">
								<div *ngIf="filters.active_toggle">
									<img *ngIf="!editing" src="./assets/icons/pencilEdit.svg">
									<img *ngIf="editing" src="./assets/icons/editing.svg">
								</div>
							</div>
							<img *ngIf="job.site.source ==='timegate'" src="./assets/icons/timegate-icon.svg">
						</div>
						<h3 class="custom-text">Date</h3>
						<h3 class="custom-text"></h3>
						<h3 class="custom-text">Start</h3>
						<h3 class="custom-text">End</h3>
						<h3 class="custom-text">Filled</h3>
						<h3 class="custom-text pay">Pay</h3>
					</div>
				</div>

				<div class="applicants-header" #applicantsScroll>
					<p class="smart-staff-header"
						*ngIf="job.smart_staff && applicants.length > 0 && !loading_anim_applicant && filters.active_toggle">
						<img src="assets/icons/smart-staff-grey.svg" alt="">
						Temp to Perm Job - Workers have been encouraged to apply to all shifts
					</p>
					<div class='applicant-avatar-container'>

						<div *ngFor="let applicant of applicants; let i = index; let last = last;">
							<!-- [ngClass]="applicant?.best_matched ? 'best-matched' : 'not-best-matched'" -->
							<div>
								<img class="left-arrow" *ngIf="i === 0 && has_prev_applicants" alt="previous page"
									[src]="mouseOverLeft ? 'assets/icons/green_left.svg' : 'assets/icons/grey_left.svg'"
									(click)="changeApplicantPage(current_applications_page - 1)"
									(mouseover)="mouseOverLeft=true" (mouseout)="mouseOverLeft=false">
								<img class="right-arrow" *ngIf="last && has_next_applicants" alt="next page"
									[src]="mouseOverRight ? 'assets/icons/green_right.svg' : 'assets/icons/grey_right.svg'"
									(click)="changeApplicantPage(current_applications_page + 1)"
									[nzTooltipTitle]="totalApplicants + ' total applicants'" nzPlacement="topRight"
									nz-tooltip (mouseover)="mouseOverRight=true" (mouseout)="mouseOverRight=false">
							</div>
							<div [ngClass]="applicantJobCompliantStyle(applicant)"
								class="applicant-border not-best-matched">

								<div *ngIf="applicant" class="applicant">
									<!-- <div *ngIf="applicant?.best_matched" class="best-matched-font">
									<p>Best Match</p>
								</div> -->
									<div class="avatar cursor-pointer">
										<div *ngIf="applicant && applicant.strikes && applicant.strikes.total > 0"
											class="strikes-container" nz-popover [nzPopoverContent]="strikesPopover"
											nzPopoverTrigger="hover" nzPlacement="right">
											<div class="strike-inner">
												<img [src]="applicant.strikes.total > 0 ? darkStrike : lightStrike">
												<img [src]="applicant.strikes.total > 1 ? darkStrike : lightStrike">
												<img [src]="applicant.strikes.total > 2 ? darkStrike : lightStrike">
											</div>

										</div>
										<div *ngIf="applicant?.has_site_training === 1" class="training-icon">
											<img src="assets/icons/onsite-training-icon.svg" nz-tooltip
												nzTooltipTitle="Site trained">
										</div>
										<nz-avatar (click)="open(applicant)"
											[nzTooltipTitle]="applicant ? applicant?.user?.name +' '+applicant?.user?.surname : ''"
											nzPlacement="top" nz-tooltip [nzText]='" "+applicant?.user?.name[0]'
											[nzSrc]="applicant?.user?.avatar" nzSize="small">
										</nz-avatar>
										<div class="full-name">
											{{applicant?.user?.name[0]|titlecase}}.<br>{{applicant?.user?.surname|titlecase}}
										</div>
										<!-- <div class="scores-container"
										[ngClass]="job.client_requirements.length === 0 ? 'no-req':''">

										<div class="skills" *ngIf="job.client_requirements.length > 0"
											nz-popover [nzPopoverContent]="skillsPopover" nzPopoverTrigger="hover">
											<img *ngIf="applicant.skillsCount === requirementsCount"
												src="assets/icons/Check.svg">
											<img *ngIf="applicant.skillsCount < requirementsCount"
												src="assets/icons/Warning.svg">
											<span> {{applicant.skillsCount}}/{{requirementsCount}}</span>

										</div>
									</div> -->
										<div *ngIf="applicant && applicant?.worker_status?.compliant_statuses?.work_job_compliant"
											nzTooltipTitle="Select all applications in view" nzPlacement="top"
											nz-tooltip (click)="selectAllApplications(applicant)"
											class="cursor-pointer">
											<img class="select-all-img" *ngIf="!applicant.applications_selected"
												src="assets/icons/selectAll.svg" />
											<img class="select-all-img" *ngIf="applicant.applications_selected"
												src="assets/icons/selected.svg" />
										</div>
										<div *ngIf="applicant && !applicant?.worker_status?.compliant_statuses?.work_job_compliant"
											nz-popover [nzPopoverContent]="heldPopover" nzPopoverTrigger="click">
											<img class="shield-icon" src="assets/icons/shield.svg">
										</div>



										<ng-template #heldPopover>
											<div class="skillsPopover">
												<div class="margin-bottom-32">
													<h4>Worker Status</h4>
													<p class="ranking-p"><img class="held-img"
															[src]="checkWorkerStatusIcon(applicant, 'rtw_status')" />
														<span class="b-font-demibold">RTW - </span>
														{{applicant.worker_status.compliant_statuses.rtw_status}}
													</p>
													<p class="ranking-p"><img class="held-img"
															[src]="checkWorkerStatusIcon(applicant, 'id_status')" />
														<span class="b-font-demibold">ID - </span>
														{{applicant.worker_status.compliant_statuses.id_status}}
													</p>
													<p class="ranking-p"><img class="held-img"
															[src]="checkWorkerStatusIcon(applicant, 'compliance_status')" />
														<span class="b-font-demibold">BGC - </span>
														{{applicant.worker_status.compliant_statuses.compliance_status}}
													</p>
													<hr>
													<p class="ranking-p"><img class="held-img"
															[src]="checkWorkerStatusIcon(applicant, 'has_tax')" /><span
															class="b-font-demibold">Tax</span> Information</p>
													<p class="ranking-p"><img class="held-img"
															[src]="checkWorkerStatusIcon(applicant, 'has_bank')" /><span
															class="b-font-demibold">Bank</span> Details
													</p>

												</div>
												<app-clib-button type="outline" color="dark" label="Go to admin"
													(pressAction)="goToAdmin(applicant)">
												</app-clib-button>
											</div>
										</ng-template>


										<ng-template #skillsPopover>
											<div class="skillsPopover">
												<div *ngIf="applicant.missingSkillsCount > 0">
													<p class="b-font-demibold">This worker is missing
														{{applicant.missingSkillsCount}} job <span
															*ngIf="applicant.missingSkillsCount === 1">requirement</span><span
															*ngIf="applicant.missingSkillsCount > 1">requirements</span>
													</p>
													<div *ngFor="let skill of applicant.missingSkills">
														<p class="ranking-p">{{skill.worker_title}}
														</p>
													</div>
												</div>
												<div *ngIf="applicant.missingSkillsCount === 0">
													<p class="b-primary b-font-demibold">This worker meets all job
														requirements</p>
												</div>

											</div>
										</ng-template>
										<ng-template #strikesPopover>
											<div class="strikesPopover">
												<h3 class="b-font-demibold">Strikes</h3>
												<div class="strike-inner">
													<img [src]="applicant.strikes.total > 0 ? darkStrike : lightStrike"
														[alt]="applicant?.user.name + applicant?.user.surname + 'has 2/3 strikes'">
													<img [src]="applicant.strikes.total > 1 ? darkStrike : lightStrike">
													<img [src]="applicant.strikes.total > 2 ? darkStrike : lightStrike">
												</div>
												<p class="expiry">Expire in: {{applicant.strikes.expires_in}} <span
														*ngIf="applicant.strikes.expires_in === 1">day</span><span
														*ngIf="applicant.strikes.expires_in > 1">days</span></p>
												<hr>
												<div class="info-header">
													<p class="b-font-demibold row reason">Reason</p>
													<p class="b-font-demibold row">Date</p>

												</div>

												<div *ngFor="let info of applicant.strikes.breakdown">
													<div class="info-body">
														<p class="row reason">{{info.type.notes}}</p>
														<p class=" row">{{info.dateIssued | dateFormat:'DD.MM.YY' :
															job?.site?.location?.timezoneId}}</p>

													</div>
												</div>


											</div>
										</ng-template>


									</div>
								</div>
							</div>




						</div>

						<nz-skeleton *ngIf="loading_anim_applicant && applicants.length === 0"
							class="nz-applicant_avatar-loading" [nzParagraph]="{ rows: 5 }" [nzTitle]="false"
							[nzActive]="true"></nz-skeleton>
					</div>


				</div>
			</div>
			<!-- </div> -->


			<div class="break">
				<div *ngIf="loading_anim">
					<div *ngFor="let number of loading_anim_numbers; let i=index">
						<nz-skeleton *ngIf="i<10" class="nz-shifts-loading" [nzParagraph]="{ rows: 6 }"
							[nzTitle]="false" [nzActive]="true">
						</nz-skeleton>
					</div>
				</div>
			</div>
			<div class="body-wrapper">

				<div class="sticky" *ngIf="shifts.length > 0">
					<div class="shifts-details" *ngFor="let shift of shifts; let i = index">
						<nz-skeleton *ngIf="!shift" class="nz-shifts-loading nz-more-shifts-loading"
							[nzParagraph]="{ rows: 5 }" [nzTitle]="false" [nzActive]="true"></nz-skeleton>
						<div *ngIf="!job.site.source" class="edit-shift-area  cursor-pointer"
							[nzTooltipTitle]="shift.now && !adminUser ? 'Cannot edit shifts in progress': ''"
							nzPlacement="topLeft" nz-tooltip>
							<div class="checkbox-wrapper checkbox-custom"
								[ngClass]="!shift.selected ? 'checkbox-dark' : ''"
								*ngIf="shift?.active && can_edit_shifts"
								[nzTooltipTitle]="i === 0 && !shift.now && !adminUser || i === 0 && shift.now && adminUser? 'Select to edit shift': ''"
								nzPlacement="top" nz-tooltip>
								<label class="b-checkbox">
									<input type="checkbox" [(ngModel)]="shift.selected"
										[disabled]="shift.now && !adminUser" (ngModelChange)="selectEditShifts(shift)">
									<span class="checkmark"></span>
								</label>
							</div>
						</div>
						<div class="timegate-placeholder edit-shift-area" *ngIf="job.site.source ==='timegate'"></div>

						<p>{{shift?.start_at | dateFormat: 'ddd' : job?.site?.location?.timezoneId |
							uppercase}}
						</p>
						<p>{{shift?.start_at | dateFormat:'DD/MM/YY' : job?.site?.location?.timezoneId}}
						</p>
						<p>{{shift?.start_at | dateFormat:'HH:mm': job?.site?.location?.timezoneId}}</p>
						<p>{{shift?.end_at | dateFormat:'HH:mm': job?.site?.location?.timezoneId}}</p>
						<div class="progress-wrapper">
							<div class="shift-progress">
								<p *ngIf="shift">
									{{shift.accepted_applications}}/{{shift.slots}}
								</p>
							</div>
						</div>


						<div *ngIf="shift && shift.hourly_rate" class="pay"
							[ngClass]="job.worker_hourly_rate == shift.hourly_rate ? 'b-light' : 'b-font-demibold'">
							<span *ngIf="!shift.is_bank_holiday">&pound;{{shift?.hourly_rate}} </span>
							<span *ngIf="shift && shift.is_bank_holiday" class="b-primary pointer">
								&pound;{{shift?.bank_holiday_pay_rate}}
								<img class="more-info" nz-popover [nzPopoverContent]="bankHolidayPopover"
									nzPopoverTrigger="hover" src="./assets/icons/moreInfo.svg">
							</span>

						</div>
						<div class="reverse-apply">
							<img *ngIf="shift.reverse_apply_flow_expiry_at && (shift.reversApplyCountDown && shift.reversApplyCountDown.hours && shift.reversApplyCountDown.minutes) && (shift.reversApplyCountDown.hours >= 0 && shift.slots > shift.accepted_applications) || (shift.reversApplyCountDown.minutes >= 0  && shift.slots > shift.accepted_applications)"
								[nzOverlayClassName]="'selected-tool-tip'" [nzTooltipTitle]="reverseApplyToolTip(shift)"
								nzPlacement="top" nz-tooltip src="assets/icons/selected-worker.svg" />
						</div>
						<ng-template #bankHolidayPopover>
							<div class="bankHolidayPopover">
								<h3 class="b-primary b-font-demibold">Bank Holiday Pay</h3>
								<p class="b-primary b-font-demibold">x{{shift.bank_holiday_rate}} Standard Pay</p>
								<p class="explainer">This shift falls on a bank holiday so we have automatically
									boosted
									the pay based on your job settings. Head to edit job if you want to
									change the rate.

								</p>
								<p class="explainer b-font-demibold">
									*The bank holiday pay rate is only applied to shift hours which fall on the bank
									holiday
								</p>
							</div>
						</ng-template>
					</div>
					<div id="scrollBottom"></div>

				</div>
				<div *ngIf="shifts.length > 0">
					<form class="applicants-details" *ngFor="let shift of shifts; let j = index" #form="ngForm">
						<div *ngFor="let application of getApplications(j); let i = index; let last = last;">
							<div [ngClass]="applicationsHeldAtStyle(application, last)" class="dots-wrapper">
								<!-- [ngClass]="applicants[i]?.best_matched ? 'best-matched-wrapper':''" -->
								<div [nzTooltipTitle]="tooltipStats(application, 'pending')" nzPlacement="top"
									nz-tooltip (click)="selectApplication(application)"
									[ngClass]="!checkSelectedApplication(application) ? 'b-background-grey-5' : ''"
									class="b-dot-large cursor-pointer"
									*ngIf="application && application.status =='pending'">
									<img [nzTooltipTitle]="'Shift conflict'" nzPlacement="top" nz-tooltip
										*ngIf="application && application.overlap" class="warning-sign"
										src="assets/icons/warningSymbol.svg" />
								</div>
								<div [nzTooltipTitle]="application && !application.overlap ? tooltipStats(application, 'declined') : ''"
									nzPlacement="top" nz-tooltip
									[ngClass]="!checkSelectedApplication(application) ? 'b-background-danger' : ''"
									(click)="selectApplication(application)" class="b-dot-large cursor-pointer"
									*ngIf="application && application.no_show == 0 && application.status =='declined'">
									<img [nzTooltipTitle]="application && application.overlap ? 'Shift conflict' : ''"
										nzPlacement="top" nz-tooltip *ngIf="application && application.overlap"
										class="warning-sign" src="assets/icons/warningSymbol.svg" />
								</div>
								<div [nzTooltipTitle]="tooltipStats(application, 'no-show')" nzPlacement="top"
									nz-tooltip class="status-img"
									*ngIf="application && application.status =='declined' && application.no_show > 0">
									<img src="assets/icons/no-show-icon.svg" />
								</div>
								<div [nzTooltipTitle]="tooltipStats(application, 'cancelled')" nzPlacement="top"
									nz-tooltip class="status-img"
									*ngIf="application && application.status =='cancelled'">
									<img src="assets/icons/cancel-icon.svg" />
								</div>
								<div [nzTooltipTitle]="tooltipStats(application, 'held')" nzPlacement="top" nz-tooltip
									class="status-img" *ngIf="application && application.held_at && adminUser">
									<img src="assets/icons/registered-interest.svg" />
								</div>
								<div [(nzPopoverVisible)]="application.expirePopOverVisible" nz-popover
									[nzPopoverContent]="application?.applicant?.worker_status?.compliant_statuses.work_job_compliant ? expirePopOver : ''" nzPopoverTrigger="click" nzPlacement="bottom"
									class="status-img cursor-pointer"
									*ngIf="application && application.status =='expiring'">
									<img src="assets/icons/expiring.svg" />
								</div>
								<div [(nzPopoverVisible)]="application.expirePopOverVisible" nz-popover
									[nzPopoverContent]="application?.applicant?.worker_status?.compliant_statuses.work_job_compliant ? expirePopOver: ''" nzPopoverTrigger="click" nzPlacement="bottom"
									class="status-img cursor-pointer"
									*ngIf="application && application.status =='expired'">
									<img src="assets/icons/expired.svg" />
								</div>
								<div (click)="selectApplication(application)" class="status-img cursor-pointer"
									*ngIf="application && application.status =='accepted' && !application.automatically_accepted">
									<div *ngIf="checkSelectedApplication(application)" class="b-dot-large"></div>
									<img [nzTooltipTitle]="tooltipStats(application, 'accepted')" nzPlacement="top"
										nz-tooltip *ngIf="!checkSelectedApplication(application)"
										src="assets/icons/check-icon.svg" />
								</div>
								<div (click)="selectApplication(application)"
									class="status-img cursor-pointer auto-approved"
									*ngIf="application && application.status =='accepted' && application.automatically_accepted">
									<div *ngIf="checkSelectedApplication(application)" class="b-dot-large"></div>
									<img [nzTooltipTitle]="tooltipStats(application, 'auto_approved')" nzPlacement="top"
										nz-tooltip *ngIf="!checkSelectedApplication(application)"
										src="assets/icons/auto-approved.svg" />
								</div>
							</div>
							<ng-template #expirePopOver>

								<div class="expirePopOver">
									<img
										[src]="application.status === 'expiring' ? 'assets/icons/expiring.svg': 'assets/icons/expired.svg'" />
									<div *ngIf="application.status === 'expiring'">
										<p class="text-spacing">This application expires in:
											<br>{{countDownExpiring(application.expires_at)}}
											<br><br>If you
											want to hire {{application?.user?.name | titlecase}}, approve this
											application before it expires.
										</p>
									</div>
									<div *ngIf="application.status === 'expired'">
										<p class="text-spacing">This job application expired on:
											{{application.expires_at | dateFormat:'DD/MM/YY HH:mm' :
											job?.site?.location?.timezoneId}}
											<br><br>If you still want to hire {{application?.user?.name | titlecase}}
											you need to request that they re-apply to the shift.
										</p>
									</div>

									<app-clib-button [loading]="expirePopUpLoader?.loading_primary"
										(pressAction)="expirePopUpAction(application)"
										[label]="application.status === 'expired' ? 'Request another application' : 'Approve now'">
									</app-clib-button>
								</div>
							</ng-template>
						</div>
					</form>
				</div>
			</div>
		</div>

		<app-edit-shifts (closeModal)="editShiftsClose($event)" [site_timezone]="job?.site?.location?.timezoneId"
			[site_postcode]="job?.site?.location?.postcode" [shift]='edit_shift' [show_modal]='edit_shifts_modal'
			[jobUuid]="jobUuid">
		</app-edit-shifts>

	</div>

</div>

<div class="pagination pagination-shifts">
	<nz-pagination nzHideOnSinglePage="false" class="b-nz-pagination" [nzSize]="'medium'"
		[nzPageIndex]="current_shift_page" [nzPageSize]="1" [nzTotal]="total_pages_shifts"
		(nzPageIndexChange)="changeShiftsPage($event)">
	</nz-pagination>
	<div *ngIf="shiftsTotal" class="total-shifts">{{totalSlots}} shifts</div>
	<div class="appl-loading">
		<app-loading-spinner color="color_primary" size="small"
			*ngIf="loading_anim_applicant && current_applications_page > 1">
		</app-loading-spinner>
	</div>

</div>
<app-floating-footer [bar_title]="edit_shift.uuids.length > 1 ? 'Shifts selected' : 'Shift selected'"
	[loading]="floating_footer_loading" [@fadeInOut] [nr]="edit_shift.uuids.length" *ngIf="editShiftsSelected"
	[extra_action]="{name:'Select All', icon:'selectAllShifts'}" [extra_action1]="{name:'Edit', icon:'approveAll'}"
	[no_accept]="{name: null}" [no_decline]="{name: null}" (onClose)="onCloseFooter()" (extraAction)="selectAll()"
	(extraAction1)="openEditShifts()" [extra_action2]="{name:'Delete', icon:'deleteShift'}"
	(extraAction2)="clickDelete()">
</app-floating-footer>



<app-floating-footer bar_title="Applications selected" [loading]="floating_footer_loading" [@fadeInOut]
	*ngIf="selected_applications?.length > 0 && job" [nr]="selected_applications.length" (onClose)="onClose()"
	(onApprove)="applicationAction('approved')" (onDecline)="applicationAction('decline')"
	[extra_action]="{name:'No Show', icon:'no-show-icon'}" (extraAction)="applicationAction('no show')">
</app-floating-footer>


<nz-drawer [nzVisible]="share_job_visible" [nzWidth]="screen_width" [nzClosable]="false" (nzOnClose)="closeShareJob()">
	<ng-container *nzDrawerContent>
		<div class="close-btn text-right"><a class="cursor-pointer" (click)="closeShareJob()"><img
					src="assets/icons/crossIcon.svg" /></a>
		</div>
		<h2 class="b-font-demibold">Share Job</h2>
		<p class="heading">Once you share the job the worker will be notified this job is available for them to apply
			to.
			<br><br>
			You can copy the link to your clipboard <span (click)="copyJobLink(shared_job)" class="b-link">here</span>,
			or
			tap the share button next to each worker.
		</p>
		<div *ngIf="shared_job" class="assign-site-area">
			<h4 class="b-font-demibold">{{shared_job?.site?.client?.name}}</h4>
			<p class="b-light">{{shared_job?.site?.location?.city}},
				{{shared_job?.site?.location?.postcode | uppercase | splitChar}}</p>
			<p class="b-light">{{shared_job?.vacancy_service?.title}}</p>
			<p class="heading" *ngIf="jobCode"><span class="b-font-demibold">Job Code:</span> {{jobCode | uppercase}}
			</p>
		</div>
		<app-search-input *ngIf="shared_job" debounce='500' placeholder='Search by applicant' (onKey)="searchWorker()"
			[(inputModel)]="search_worker"></app-search-input>
		<br>
		<nz-skeleton *ngIf="loading_shared_job" class="nz-timesheets-loading" [nzParagraph]="{ rows: 3 }"
			[nzTitle]="false" [nzActive]="true">
		</nz-skeleton>

		<div *ngFor="let worker of shared_applicants">
			<app-worker-row [worker]='worker' (openProfile)="open(worker)" primary_button='Share'
				(primaryButton)='sendJobToWOrker($event, worker)'></app-worker-row>
		</div>

		<div class="text-center" *ngIf="shared_applicants.length == 0 && !loading_shared_job">
			<p>No workers found.</p>
			<p class='b-light'>We couldn't find any workers.</p>
		</div>

		<div class="pagination">
			<nz-pagination nzSimple nzHideOnSinglePage="true" class="b-nz-pagination" [nzSize]="'medium'"
				[nzPageIndex]="page_workers" [nzPageSize]="1" [nzTotal]="total_pages_workers"
				(nzPageIndexChange)="changePage($event)">
			</nz-pagination>
		</div>


	</ng-container>
</nz-drawer>

<nz-drawer [(nzVisible)]="viewJobVisible" nzWidth="375px" [nzClosable]="false" (nzOnClose)="closeViewJob()">
	<ng-container *nzDrawerContent>
		<div class="close-btn text-right"><a class="cursor-pointer" (click)="closeViewJob()"><img
					src="assets/icons/crossIcon.svg" /></a>
		</div>
		<app-job-view *ngIf="viewJobVisible" [job]="job"></app-job-view>
	</ng-container>
</nz-drawer>

<nz-drawer [(nzVisible)]="profile_visible" nzWidth="375px" [nzClosable]="false" (nzOnClose)="close()">
	<ng-container *nzDrawerContent>
		<div class="close-btn text-right"><a class="cursor-pointer" (click)="close()"><img
					src="assets/icons/crossIcon.svg" /></a>
		</div>
		<lib-worker-profile *ngIf="jobLocation && profile && profile.user && applicant" (onClose)="close()"
			[jobLocation]="jobLocation" [siteUuid]="job?.site?.uuid" [profile]="profile" [priceData]="priceData"
			[applicant]="applicant" [applicantBlocked]="applicantBlocked"
			(onSiteTrainingAction)="handleSiteTraining($event)" (hireRequest)="requestToHire($event)"
			[hireRequestResult]="hireRequestResult" (blockWorker)="blacklistWorker($event)">
		</lib-worker-profile>
	</ng-container>
</nz-drawer>

<lib-modal [show_modal]="modalVisible" [btn_disabled_primary]="!blockWorkerObj.reason"
	(closeModal)="closeBlockWorkerModal()" [width]='668' btn_label_primary="Confirm" btn_color_primary="primary"
	btn_label_secondary="Cancel" (clickSecondary)="closeBlockWorkerModal()" (clickPrimary)="confirmBlockWorker()"
	[close_icon]='true' [body]="modalContent">
	<div class="input-wrapper-modal">
		<h3 class="b-font-demibold">Please provide a reason for blocking this worker.</h3>
		<p class="b-light title-margin">Blocked workers will not be able to apply to any further shifts at this
			site
			and
			any booked
			shifts will be cancelled.</p>
		<div *ngFor="let i of blacklistReasons" class="checkbox-div">
			<label class="b-checkbox">
				<p>{{i.reason}}</p>
				<input type="radio" [checked]="i.selected" (click)="selectBlacklistReason(i.reason)">
				<span class="checkmark"></span>
			</label>
			<input *ngIf="i.reason === 'Other' && i.selected" class=" b-input" required
				[(ngModel)]="blockWorkerObj.reason" type="text" placeholder="Enter reason">
		</div>

	</div>
</lib-modal>

<lib-modal [width]="462" [show_modal]="deleteModalVisible" [btn_disabled_primary]="!deleteShiftsReason?.trim()"
	(closeModal)="closeDeleteModal()" btn_label_primary="Confirm" btn_color_primary="primary"
	btn_label_secondary="Cancel" (clickSecondary)="closeDeleteModal()" (clickPrimary)="deleteShifts()"
	[close_icon]='false' [body]="modalContent">
	<div class="input-wrapper-modal">
		<h4 class="b-font-demibold title-margin">Please provide a reason for deleting <span
				*ngIf="edit_shift && edit_shift.uuids && edit_shift.uuids.length === 1">this
				shift.</span><span *ngIf="edit_shift && edit_shift.uuids && edit_shift.uuids.length > 1">these
				shifts.</span>
		</h4>
		<div *ngFor="let i of jobProvider.deleteReasons" class="checkbox-div">
			<label class="b-checkbox">
				<p>{{i.reason === 'Smart-Staffed Worker' ? 'Temp to Perm Worker' : i.reason}}</p>
				<input type="radio" [checked]="i.selected" (click)="selectDeleteReason(i.reason)">
				<span class="checkmark"></span>
			</label>
			<input *ngIf="i.reason === 'Other' && i.selected" class=" b-input" required [(ngModel)]="deleteShiftsReason"
				type="text" placeholder="Enter reason">
		</div>

	</div>
</lib-modal>
<lib-modal [width]="500" [show_modal]="deleteShiftsBeta" (closeModal)="closeDeleteShiftsBeta()" [footer]='false'
	[close_icon]='true' [body]="modalContent">
	<div class="margin-bottom-32">
		<h4 class="b-font-demibold title-margin">Are you sure?
		</h4>


		<p class="margin-bottom-32"><span class="beta">Beta</span>You can choose to escalate this with our Operations
			team, who will prioritise driving suitable applicants to
			this work.</p>


		<p>If you choose to delete, <span *ngIf="edit_shift?.uuids?.length === 1">this shift.</span><span
				*ngIf="edit_shift?.uuids?.length > 1">these
				shifts.</span> will be removed.</p>
	</div>
	<div class="group-buttons">
		<app-clib-button color="secondary" label="Cancel" (pressAction)="closeDeleteShiftsBeta()">
		</app-clib-button>

		<app-clib-button color="warning" label="Delete" (pressAction)="deleteShiftsBetaContinue()">
		</app-clib-button>
		<app-clib-button [loading]="escalateLoadingBtn" color="primary" label="Escalate"
			(pressAction)="escalateShifts()">
		</app-clib-button>
	</div>
</lib-modal>

<nz-drawer [(nzVisible)]="blockedWorkers" nzWidth="375px" [nzClosable]="false" (nzOnClose)="closeBlocked()">
	<ng-container *nzDrawerContent>
		<div class="close-btn text-right"><a class="cursor-pointer" (click)="closeBlocked()"><img
					src="assets/icons/crossIcon.svg" /></a>
		</div>
		<h2 class="b-font-demibold">Blocked Workers</h2>
		<p class="heading">These workers are currently blocked from viewing or applying to jobs on this site.
		</p>
		<div *ngFor="let worker of workersBlocked; let i = index;">
			<app-worker-row [worker]='worker' primary_button='Unblock' [blockedWorkers]="blockedWorkers"
				(primaryButton)='unblockWorker($event, worker, i)'></app-worker-row>
		</div>
	</ng-container>
</nz-drawer>