import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompaniesService, JobsProviders, JobsService, FiltersServices, UsersService } from 'src/services/public_api';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { AuthSessionService } from '@broadstone/auth-session-service';

@Component({
	selector: 'app-site',
	templateUrl: './site.component.html',
	styleUrls: ['./site.component.scss']
})

export class SiteComponent implements OnInit {
	select_role: boolean = false;
	site_obj: any;
	siteUuid: any;
	private sub: any;
	job: any;
	url_job: any;
	jobUuid: string;
	search_box_input = [{ title: 'Applicant', value: 'applicant_name' }];
	currentUser: any;
	can_edit_shifts: boolean;
	edit_applications: boolean;
	subNavButton: any;

	constructor(
		public filters: FiltersServices,
		private jobservice: JobsService,
		private router: Router,
		private route: ActivatedRoute,
		private companies: CompaniesService,
		public jobData: JobsProviders,
		private popNotif: PopNotificationService,
		public user: UsersService,
		private session: AuthSessionService,
	) {
		if (this.session.geAdminHeader()) {
			this.edit_applications = true;
			this.jobData.editJobs = true;
			this.can_edit_shifts = true;
		}
		else {
			this.user.getCurrentUser().then((res) => {
				this.checkPermissions(res.permissions)
			}).catch(e => {
				console.log(e);
			});
		}
	}
	ngOnInit(): void {
		this.sub = this.route.params.subscribe(params => {
			this.siteUuid = params['site_uuid']; // (+) converts string 'id' to a number
			this.jobUuid = params['job_uuid'];
			if (this.siteUuid) {
				this.getSite();
			} else {
				if (this.jobUuid) { // If we have a jobUuid in url we mark the component as edited componet
					this.getJob();
					this.subNavButton = {
						label: 'Add Shifts',
						redirectUrl: 'jobs/manage-shifts/' + this.jobUuid
					}
				}
			}
		});
	}


	ngOnDestroy() {
		this.sub.unsubscribe();
		this.jobData.schedule_discard_page = false;
		this.filters.reset_applicant();
		this.jobData.clearPageSetup();
	}

	checkPermissions(permissions) {
		this.jobData.editJobs = false;
		if (!permissions) {
			return;
		}
		permissions.forEach(element => {
			if (element.type === 'company.jobs.edit') {
				this.jobData.editJobs = true;
			}
			else if (element.type === 'company.shifts.edit') {
				this.can_edit_shifts = true;
			}
			else if (element.type === 'company.applications.edit') {
				this.edit_applications = true;
			}
		})
	}

	getSite() {
		this.companies.getSiteUuid(this.siteUuid).then(res => {
			this.site_obj = res;
			this.siteUuid = res.uuid;
			this.jobData.storeSiteData(res, 'active_site');
		}).catch(e => {
			this.router.navigate(['jobs']);
			// this.logger.error(e);
		});
	}

	getJob() {
		if (!this.jobUuid) {
			return;
		}
		const cache = this.jobservice.shiftsPosted ? false : true;
		this.jobservice.getJob(this.jobUuid, cache).then((result) => {
			this.url_job = result;
			this.siteUuid = result.site.uuid;
			this.jobData.storeSiteData(result.site, 'active_site');
			this.jobData.editJobs = (result.site['source'] === 'timegate') ? false : this.jobData.editJobs;
		}).catch((err) => {
			this.popNotif.createNotification('error', 'There has been a problem.', 'Looks like the role was not found.');
			this.router.navigate(['jobs']);
			// this.logger.error(err);
		});
	}

	setBreadcrumbs() {
		if (this.siteUuid && this.site_obj) {
			this.jobData.pageSetup.pageTitle = this.job.title ? this.job.title : this.job.service.title;//setting the title of the page
			this.jobData.pageSetup.actionOne.link = 'jobs/role/' + this.jobUuid + '/edit';
			this.jobData.pageSetup.actionOne.tooltip = 'Edit Job';
			this.jobData.pageSetup.actionTwo = 'Share job through the Orka Works mobile app';
			this.jobData.pageSetup.actionFour = 'View Job Details';
			this.jobData.pageSetup.actionThree.link = "/jobs/manage-site/" + this.siteUuid;
			this.jobData.pageSetup.actionThree.tooltip = 'Edit Site';
			this.jobData.pageSetup.extraInfo = this.site_obj.location.postcode;
		}
	}

	expanded(job) {
		this.job = job;

		this.jobData.schedule_discard_page = true;
		this.site_obj = this.job.site;
		this.setBreadcrumbs();
		if (!this.jobUuid) {
			this.navigateToJob(job.uuid); // shows the job uuid in Url
		}

	}


	navigateToJob(jobUuid) {
		this.router.navigate([], {
			queryParams: {
				job: jobUuid
			},
			queryParamsHandling: 'merge',
		});
	}

	editShifts() {
		this.jobData.site_to_shifts = true;
		this.router.navigate(['jobs/manage-shifts', this.job.uuid]);
	}

	addRole() {
		this.select_role = true;
		this.router.navigateByUrl('jobs/role/' + this.siteUuid + '/create');
	}

	clearFilters() {
		this.filters.clear_site_filters();
	}
	goToAdminWorkers(mobile) {
		this.router.navigate(['/admin/workers', mobile]);
		this.session.setAdminHeader(null);
	}

}
