import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { JobsService, AppAnimations, ShiftsService, ApplicationsService, FiltersServices, EventsTracker, SmartStaffService, JobsProviders, LocalHelpers } from 'src/services/public_api';
import { GlobalHelpers, DatesHelper, Costs } from '@broadstone/helpers';
import { isEqual } from 'lodash';
import { PopNotificationService } from '@broadstone/pop-notification-service';
import { AlertService } from '@broadstone/alert';
import { AuthSessionService } from '@broadstone/auth-session-service';

@Component({
	selector: 'app-site-jobs',
	templateUrl: './site-jobs.component.html',
	styleUrls: ['./site-jobs.component.scss'],
	animations: [AppAnimations.site_job_animation, AppAnimations.fade_in_out],
	changeDetection: ChangeDetectionStrategy.Default
})
export class SiteJobsComponent implements OnInit {
	@Input() can_edit_shifts: boolean;
	@Input() edit_applications: boolean;
	@Input() job: any; // is available only if job uuid was paste in url.
	@Output() onExpand: EventEmitter<any> = new EventEmitter();
	@Output() onAddShifts: EventEmitter<any> = new EventEmitter();
	@Output() goToAdminWorkers: EventEmitter<any> = new EventEmitter();
	screen_width = window.innerWidth > 375 ? 375 : window.innerWidth;
	profile: any = {};

	jobLocation: any;
	jobUuid: string;
	shifts: any = [];
	shiftsTotal: any;
	shfitsPerPage = 15;
	indexed_shift: any = {};
	applicants: any = [];
	applications: any = [];
	editing: boolean = false;

	perPage: number = 7;
	has_next_applicants: boolean;
	has_prev_applicants: boolean;
	current_applications_page: number = 1;
	prev_applications_page: number = 0;
	total_pages_shifts: number;
	current_shift_page: number = 1;
	loading_anim: boolean = true;
	loading_anim_numbers: any = [];
	loading_anim_applicant: boolean = true;
	selected_applications: any = [];
	profile_visible = false;
	applicant: any;

	floating_footer_loading: boolean = false;
	filters_on: boolean = false;
	filter_subscription: boolean = true;
	no_applicants_found: boolean = false;
	edit_shifts_modal: boolean = false;
	edit_shift: any;
	jobs_temp_filters: any = {};

	shared_applicants: any = [];
	share_job_visible: boolean = false;
	shared_job: any = null;
	loading_shared_job: boolean = false;
	page_workers: number = 1;
	search_worker: any;
	total_pages_workers: number;

	shift_uuids: any = [];
	editShiftsSelected: boolean = false;
	priceData: any = null;
	hireRequestResult: string;
	blockWorkerObj: any = { 'reason': '', 'site': '', 'uuid': '' };
	modalVisible: boolean = false;
	modalContent: any;
	blacklistReasons: any = [{ reason: 'Multiple Cancellations', selected: false }, { reason: 'No Shows', selected: false }, { reason: 'Behavioural Issue on Site', selected: false }, { reason: 'Negative Client Feedback', selected: false }, { reason: 'Other', selected: false }]
	jobCode: string;
	requirementsCount: number = 0;
	applicantCount: number = 0;
	adminUser: boolean = false;
	lightStrike: string = 'assets/icons/paleStrike.svg';
	darkStrike: string = 'assets/icons/darkStrike.svg';
	deleteShiftsReason: string = '';
	deleteModalVisible: boolean = false;
	blockedWorkers: boolean = false;
	workersBlocked: any = [];
	applicantBlocked: boolean = false;
	totalSlots: number = 0;
	totalApplicants: number = 0;
	mouseOverLeft: boolean = false;
	mouseOverRight: boolean = false;
	expirePopUpLoader: any = { loading_primary: false };
	skipCaching: boolean = false;
	viewJobVisible: boolean = false;
	deleteShiftsBeta: boolean = false;
	escalateLoadingBtn: boolean = false;
	constructor(
		private date_helper: DatesHelper,
		private filters: FiltersServices,
		public jobs: JobsService,
		private shifts_service: ShiftsService,
		private applications_service: ApplicationsService,
		public globalHelper: GlobalHelpers,
		private popNotif: PopNotificationService,
		private global_helpers: GlobalHelpers,
		private event_tracker: EventsTracker,
		public costHelper: Costs,
		private smartStaff: SmartStaffService,
		private alert: AlertService,
		protected session: AuthSessionService,
		public jobProvider: JobsProviders,
		private localHelpers: LocalHelpers
	) {
		this.loadPrices();
	}

	ngOnInit() {
		this.filter_subscription = true;
		this.jobProvider.viewInfoJobEmitter.subscribe(value => {
			this.viewJobVisible = true;
			this.localHelpers.intercomBobbleVisibility('none');
		});
		this.jobProvider.shareJobEmitter.subscribe(value => {
			this.shareJob(this.job)
		});

	}

	ngOnDestroy() {
		this.reset_page_data(true, true, true);
		this.filter_subscription = false;

	}

	ngOnChanges() {
		if (this.job) {
			this.calculateMaxApplicants();
			this.subscribeToFilters();
			this.compareFilters();
			this.getBlockedWorkers(this.job.site.uuid);
		}
		this.adminUser = this.session.getIsAdmin();
	}



	compareFilters() {  //Check if filters changed and load jobs again when collapsing job

		let custom_filters = {
			"active_toggle": this.filters.active_toggle,
			"applicant_name": this.filters.applicant_name,
			"start_at": this.filters.start_at,
			"end_at": this.filters.end_at
		};
		if (!isEqual(custom_filters, this.jobs_temp_filters)) {
			this.jobs_temp_filters = custom_filters;
			this.expandJob();
		}
	}

	loadPrices() {
		this.smartStaff.getStaffPrices().then((res) =>
			this.priceData = res
		)
	}

	calculateMaxApplicants() {
		let width = window.innerWidth;
		if (width > 1700) {
			this.perPage = 10;
		}
		else if (width > 1600) {
			this.perPage = 9;
		}
		else if (width > 1500) {
			this.perPage = 8;
		}
		else if (width < 1500) {
			this.perPage = 7;
		}
	}

	subscribeToFilters() {
		this.filters.isSearchingSite.subscribe((data) => {
			if (this.filter_subscription) {
				this.filters_on = this.filters.check_active_filters();
				this.loadingAnimSkeleton(this.job); // show shifts loading animation when filter is trigger fro calendar or active toggle
				this.reset_page_data(true, true, true);
				this.current_shift_page = 1;
				this.loadDataAsync();

			}
		});
	}


	getBlockedWorkers(siteUuid) {
		this.workersBlocked.length = 0;
		this.applications_service.getSiteBlacklisted(siteUuid).then((res) => {
			this.workersBlocked = res.data;
		}).catch((err) => {
			console.log(err);
		});
	}

	loadApplications() {
		return new Promise<void>(resolve => {
			const shiftUuids = this.shifts.map((item) => {
				return item.uuid;
			});
			const applicantUuids = this.applicants.map((item) => {
				return item.uuid;
			});
			const startShiftIndex = 0;
			const startApplicantIndex = 0;
			this.getApiApplications(shiftUuids, applicantUuids).then((response: any) => {
				for (let i = 0; i < shiftUuids.length; i++) {
					if (!this.applications[startShiftIndex + i]) {
						this.applications[startShiftIndex + i] = [];
					}
					for (let j = 0; j < applicantUuids.length; j++) {
						const shiftUuid = shiftUuids[i];
						const applicantUuid = applicantUuids[j];
						const applications = response.data.filter(item => {
							return shiftUuid === item.shift_uuid && applicantUuid === item.profile_uuid;
						});
						if (applications.length > 0) {
							this.applications[startShiftIndex + i][startApplicantIndex + j] = applications[0];
							this.applications[startShiftIndex + i][startApplicantIndex + j]['unique_uuid'] = applications[0].uuid;
							this.applications[startShiftIndex + i][startApplicantIndex + j]['user'] = this.applicants[j].user;
							this.applications[startShiftIndex + i][startApplicantIndex + j]['applicant'] = this.applicants[j];
						} else {
							this.applications[startShiftIndex + i][startApplicantIndex + j] = { noData: true, applicant: this.applicants[j] };
						}
					}
				}

				resolve();
			}).catch(err => {
				// this.logger.error(err);
			});
		});
	}

	getApplications(verticalIndex: number) {
		if (this.applications.length > 0) {
			return this.applications[verticalIndex];
		} else {
			return [];
		}
	}

	async expandJob() {
		this.onExpand.emit(this.job);
		this.job.client_requirements.forEach(requirement => {
			this.requirementsCount++;
		});

		this.jobLocation = this.job.site.location;
		this.loadingAnimSkeleton(this.job);


		this.jobUuid = this.job.uuid;
		this.jobCode = this.jobUuid.slice(this.jobUuid.length - 8);
		this.skipCaching = this.jobs.shiftsPosted ? true : false;
		await this.loadDataAsync();
		this.jobs.shiftsPosted = false;
	}

	reset_page_data(shifts, applicants, applications) {
		if (shifts) {
			this.shifts.length = 0;
			this.indexed_shift = {};
		}
		if (applicants) {
			this.applicants.length = 0;
		}
		if (applications) {
			this.applications_service.allApplications.length = 0;
			this.applications.length = 0;
		}
		this.current_applications_page = 1;
		this.prev_applications_page = 0;
	}

	getApiApplicants(page) {
		this.prev_applications_page = page;
		this.loading_anim_applicant = true;
		this.no_applicants_found = false;
		const cache = this.skipCaching ? false : true;
		return new Promise((resolve, reject) => {
			this.applications_service.getApplicants(this.jobUuid, page, this.perPage, this.filters, null, cache).then((result) => {
				result['data'].forEach(applicant => {
					this.compareSkills(applicant);
					// Push applicant if does not exists
					const found = this.applicants.filter(added_applicant => {
						return added_applicant.uuid === applicant.uuid;
					}).length > 0;
					if (!found) {
						this.applicants.push(applicant);
					}
				});
				this.loading_anim_applicant = false;
				this.has_next_applicants = result.links.next ? true : false;
				this.has_prev_applicants = result.links.prev ? true : false;
				this.no_applicants_found = result.meta.total > 0 ? false : true;
				this.totalApplicants = result.meta.total;
				if (page === 1 && !this.filters_on && !this.filters.status && !this.no_applicants_found) {
					this.checkMissingApplicant(result.meta.total);
				}
				resolve(result)
			}).catch((err) => {
				reject(err);
				this.no_applicants_found = true;
				// this.logger.error(err);
				this.loading_anim_applicant = false;
			});
		});
	}
	checkMissingApplicant(totalApplicants) {
		// The api will log if an applicant is missing from schedule page.
		const job_profiles = this.applicants.map(applicant => applicant.uuid);
		const data = {
			job_uuid: this.jobUuid,
			total_applicants: totalApplicants,
			job_profiles: job_profiles,
			logged_at: new Date().toISOString()
		}
		this.applications_service.recordMissingApplicants(data).catch(e => { });
	}
	getApiShifts(page) {
		const cache = this.skipCaching ? false : true;
		return new Promise((resolve, reject) => {
			this.shifts_service.getShifts(this.jobUuid, page, this.shfitsPerPage, this.filters, cache).then((result) => {
				this.total_pages_shifts = result['metaData'].last_page;
				this.shiftsTotal = result['metaData'].total;
				this.totalSlots = result['total_slots'];
				result['data'].forEach(shift => {
					shift.selected = false;
					shift.uuids = [];
					shift.bank_holiday_pay_rate = this.costHelper.to_fixed(shift.bank_holiday_rate * shift.hourly_rate);
					shift.active = this.checkPast(shift);
					shift.now = this.date_helper.isInPast(shift.start_at);
					shift.reversApplyCountDown = this.countDown(new Date(), shift.reverse_apply_flow_expiry_at)
					this.indexed_shift[shift.uuid] = shift;
				});
				this.shifts = Object.keys(this.indexed_shift).map((key) => {
					return this.indexed_shift[key];
				});
				resolve(result);
				this.loading_anim = false;
			}).catch((err) => {
				reject(err);
				this.loading_anim = false;
				// this.logger.error(err);
			});
		});
	}

	countDown(now, endAt, withDays = false) {
		const endAtDate = new Date(endAt);
		const nowDate = new Date(now);

		const seconds = 1000;
		const min = seconds * 60;
		const hour = min * 60;
		const day = hour * 24;
		const distance = endAtDate.getTime() - nowDate.getTime();
		const days = Math.floor(distance / (day));
		const minutes = Math.floor((distance % hour) / min);

		if (withDays) {
			const hours = Math.floor((distance % day) / hour);
			return { days: days, hours: hours, minutes: minutes };
		} else {
			let hours = Math.floor((distance) / hour);
			if (hours < 0) {
				hours = hours + 1;
			}
			return { hours: hours, minutes: minutes };
		}

	}

	countDownExpiring(date) {
		// const date = '2022/07/28 13:10:35 +0000';
		const data = this.countDown(new Date(), date, true);
		const copyDays = data.days > 1 ? 'days' : 'day';
		const copyHours = data.hours > 1 ? 'hours' : 'hour';
		const copyMins = data.minutes > 1 ? 'mins' : 'min';
		const days = data.days > 0 ? `${data.days} ${copyDays}:` : '';
		return `${days} ${data.hours} ${copyHours} : ${data.minutes} ${copyMins}`;
	}

	reverseApplyToolTip(shift) {
		const startText = 'Worker selected. ';
		const endText = 'left for the shift to show to all workers';
		let returnText = '';
		if (shift.reversApplyCountDown.hours > 0) {
			returnText = startText + shift.reversApplyCountDown.hours + 'Hrs ' + endText;
		} else {
			returnText = startText + shift.reversApplyCountDown.minutes + 'Min ' + endText;
		}
		return returnText;
	}

	getApiApplications(shiftsUuids, applicantUuids) {
		const shifts_uuids = shiftsUuids.join(',');
		const applicant_uuids = applicantUuids.join(',');
		const cache = this.skipCaching ? false : true;
		return new Promise((resolve, reject) => {
			this.applications_service.getApplications(shifts_uuids, applicant_uuids, this.filters, 1, cache).then((result) => {
				resolve(result)
			}).catch((err) => {
				reject(err);
				// this.logger.error(err);
			});
		});
	}

	compareSkills(applicant) {
		applicant.skillsCount = 0;
		applicant.matchedSkills = [];
		applicant.skills_qualifications.forEach(group => {
			group.requirements.forEach(skill => {
				this.job.client_requirements.forEach(req => {
					if (skill.uuid === req.uuid) {
						applicant.skillsCount++;
						applicant.matchedSkills.push(skill)
					}
				});
			});
		});
		applicant.missingSkills = this.job.client_requirements.filter(function (obj) {
			return !applicant.matchedSkills.some(function (obj2) {
				return obj.uuid == obj2.uuid;
			});
		});
		applicant.missingSkillsCount = this.requirementsCount - applicant.skillsCount;
	}


	loadingAnimSkeleton(job) {
		this.loading_anim = true;  //create shift rows loading animation base on shifts rows in the job.
		if (job.shifts_count && job.shifts_count > 0) {
			this.loading_anim_numbers = Array(job.shifts_count).fill(0).map((x, i) => i);
		} else {
			this.loading_anim_numbers = [];
		}
	}

	addShifts() {
		this.onAddShifts.emit();
	}

	selectApplication(application, selected_applicant?) {
		const { work_job_compliant } = application?.applicant?.worker_status?.compliant_statuses;
		if (!work_job_compliant) {
			return;
		}
		if (this.edit_applications) {
			if (application && application.overlap) {
				application.overlap = false;
			}
			if (!this.checkSelectedApplication(application)) {
				if (application && application.uuid) {
					this.selected_applications.push(application);
					this.onCloseFooter();
					const shift1 = this.shifts[this.shifts.length - 1]; //get last item in the array
					const shift2 = this.shifts[this.shifts.length - 2];
					if (shift1.uuid === application.shift_uuid || shift2.uuid === application.shift_uuid) {
						this.globalHelper.scroll_to_section('scrollBottom');
					}
				}
			} else {
				this.selected_applications.splice(this.selected_applications.indexOf(application), 1);
				if (!selected_applicant) { // When unselect all
					const found_applicant = this.applicants.filter(applicant => {
						if (application && application.profile_uuid) {
							return (application.profile_uuid === applicant.uuid) && applicant.applications_selected;
						}
					});
					if (found_applicant && found_applicant.length > 0) {
						found_applicant[0].applications_selected = false;
					}
				}
			}
		}
		else {
			return;
		}
	}

	checkSelectedApplication(application) {
		return this.selected_applications.filter(selected => {
			if (selected && selected.unique_uuid) {
				return selected.unique_uuid == application.unique_uuid;
			}
			return false;
		}).length > 0;
	}

	async open(applicant) {
		if (!this.globalHelper.preventClickTwice()) return;
		this.applicant = applicant;
		await this.getProfile(applicant.uuid)
		await this.getBlackListStatus(applicant.uuid)
	}

	getProfile(uuid) {
		this.applications_service.getApplicantProfile(uuid).then((result) => {
			this.profile = { ...result };
			this.profile_visible = true;
		}).catch((err) => {
			// this.logger.error(err);
		});
	}

	getBlackListStatus(applicantUuid) {
		let workerObj = {
			site: this.job.site.uuid,
			applicant: applicantUuid
		}
		this.applications_service.getBlacklistStatus(workerObj).then((res) => {
			this.applicantBlocked = res.data.blacklisted;
		}).catch((err) => {
			console.log(err);
		});
	}

	handleSiteTraining(request) {
		this.hireRequestResult = '';
		if (request.action === 'add') {
			this.applications_service.assignWorkerTrainedOnSite(request.site, request.uuid).then(() => {
				this.popNotif.createNotification('success', 'Success', 'Site training added.')
			}).catch((err) => {
				// this.logger.error(err);
			})
		} else if (request.action === 'remove') {
			this.applications_service.unassignWorkerTrainedOnSite(request.site, request.uuid).then(() => {
				this.popNotif.createNotification('success', 'Success', 'Site training removed.')
			}).catch((err) => {
				// this.logger.error(err);
			})
		}
	}

	requestToHire(e) {
		this.hireRequestResult = '';
		this.smartStaff.sendHireRequest(e).then((res) => {
			this.hireRequestResult = 'success';
		})
			.catch((err) => {
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', 'Please try again later!')
			});
	}

	close(): void {

		this.profile_visible = false;
		this.applicant = null;
		this.profile = {};
		this.applicantBlocked = false;
		this.hireRequestResult = '';
	}

	closeBlocked() {
		this.blockedWorkers = false;
	}

	onClose() {
		this.selected_applications.length = 0;
	}

	applicationAction(action) {
		this.floating_footer_loading = true;
		const applications_uuid = this.selected_applications.map(element => {
			return element.uuid;
		});
		const applications = { applications: applications_uuid };
		switch (action) {
			case 'approved':
				this.approveApplications(applications);
				break;
			case 'decline':
				this.declineApplications(applications);
				break;
			case 'no show':
				this.markAsNoShow(applications_uuid, this.selected_applications);
				break;
		}
	}

	approveApplications(applications) {
		return this.applications_service.approveApplications(applications).then((result: any) => {
			this.event_tracker.trackEvent('approve_applicant', result);
			this.refreshApplicationsAction().then(() => {
				this.floating_footer_loading = false;
				if (result.errors && Object.values(result.errors).length > 0) {
					let reason = '';

					if (result.errors.noSlots && result.errors.noSlots.length > 0) {
						reason = 'No Slots: All the slots are already filled for the shift - This job has already been filled. If you would like to book an additional officer for this shift please edit the shift and increase applicants required.<br><br>'
					}

					if (result.errors.notSiteTrained && result.errors.notSiteTrained.length > 0) {
						reason += 'Staff member is not site trained - One of the staff members you are trying to book is not site-trained. Please update their training status on their profile.<br><br>'
					}

					if (result.errors.overlap && result.errors.overlap.length > 0) {
						reason += 'Application Overlaps - It looks like the selected shifts overlap. Please make sure none of the shifts overlap and try again.<br><br>'
					}

					if (result.errors.hoursLimit && result.errors.hoursLimit.length > 0) {
						reason += 'Hours Exceeded - One of the workers you are trying to book has already been booked for the maximum amount of hours today.<br><br>'
					}

					if (result.errors.failedWelfareChecks && result.errors.failedWelfareChecks.length > 0) {
						reason += 'Working time directive - One of the workers you are trying to book does not comply with the working time directive.<br><br>'
					}

					this.popNotif.createNotification('error', 'Sorry!', reason);

				} else {
					this.popNotif.createNotification('success', 'Success', 'All selected applications have been approved.');
				}
				this.onClose();

			})
		}).catch((err) => {
			this.floating_footer_loading = false;
			// this.logger.error(err);
			this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot approve this applications. Please try again later.');
			this.onClose();
		});
	}

	declineApplications(applications) {
		this.applications_service.declineApplications(applications).then((result) => {
			this.event_tracker.trackEvent('decline_applicant', { data: result });
			this.refreshApplicationsAction().then(() => {
				this.floating_footer_loading = false;
				this.onClose();
				this.popNotif.createNotification('success', 'Success', 'All selected applications have been declined.');
			});

		}).catch((err) => {
			this.floating_footer_loading = false;
			// this.logger.error(err);
			this.popNotif.createNotification('error', 'There has been a problem.', 'We cannot decline this applications. Please try again later.');
			this.onClose();
		});
	}

	markAsNoShow(applications, applicationsInfo) {
		let accepted = true;
		applicationsInfo.forEach(app => {
			if (!app.accepted_at) {
				accepted = false;
			}
		});
		if (!accepted) {
			this.popNotif.createNotification('error', 'There has been a problem', 'You can only mark accepted applications as a no show.')
			this.floating_footer_loading = false;
			return;
		}
		else {
			const promisesCompleted = [];
			applications.forEach(uuid => {
				promisesCompleted.push(this.applications_service.setNoShow(uuid));
			});
			Promise.all(promisesCompleted)
				.then(res => {
					if (applications.length === 1) {
						this.popNotif.createNotification('success', 'Success', 'Selected application marked as no show.');
					} else if (applications.length > 1) {
						this.popNotif.createNotification('success', 'Success', 'All selected applications marked as no show.');
					}
					this.floating_footer_loading = false;
					this.onClose();
					this.blockWorkerRefresh();
				})
				.catch(err => {
					this.floating_footer_loading = false;
					// this.logger.error(err);
					this.popNotif.createNotification('error', 'There has been a problem.', err);
					this.onClose();
					this.blockWorkerRefresh();
				});
		}
	}

	async loadDataAsync(shifts = true) {
		if (shifts) {
			await this.getApiShifts(this.current_shift_page);
		}
		if (this.current_applications_page != this.prev_applications_page) {
			await this.getApiApplicants(this.current_applications_page);
			await this.loadApplications();
		}
		this.skipCaching = false;
	}

	async refreshApplicationsAction() {
		this.skipCaching = true;
		this.applications_service.allApplications.length = 0;
		await this.getApiApplicants(this.current_applications_page);
		await this.loadApplications();
		await this.getApiShifts(this.current_shift_page);
		this.skipCaching = false;
	}

	checkShifts() {
		let check = false;
		if (this.shifts.length == 0 && !this.loading_anim) {
			check = true;
		}
		return check;
	}



	tooltipStats(application, status) {
		const statusName = (status === 'auto_approved') ? 'Auto approved' : 'Approved'
		const approved = (application && application.accepted_at) ? `${statusName}: ${this.date_helper.format_date_time(application.accepted_at)}` : '';
		switch (status) {
			case 'cancelled':
				return `
				${approved}
				Cancelled: ${this.date_helper.format_date_time(application.cancelled_at)}`;
			case 'declined':
				return `Declined: ${this.date_helper.format_date_time(application.declined_at)}`;
			case 'accepted':
			case 'auto_approved':
				if (application.clocked_in_at) {
					let clock = `Clocked In: ${this.date_helper.format_time(application.clocked_in_at)}`;
					if (application.clocked_out_at) {
						clock += `, Clocked Out: ${this.date_helper.format_time(application.clocked_out_at)}`;
					}
					return clock;
				} else {
					return approved;
				}
			case 'no-show':
				return `
				${approved}
				Marked as No Show`;
			case 'held':
				return `Registered Interest`;
			case 'pending':
				if (application.created_at) {
					return `Applied: ${this.date_helper.format_date_time(application.created_at)}`;
				}
		}
	}

	selectEditShifts(shift) {
		if (shift.selected) {
			this.editing = true;
			this.shift_uuids.push(shift.uuid)
		}
		else {
			this.shift_uuids.forEach(function (uuid, index, object) {
				if (uuid === shift.uuid) {
					object.splice(index, 1);

				}
			});
		}
		shift.uuids = this.shift_uuids;
		if (this.shift_uuids.length === 0) {
			this.onCloseFooter();
		}
		else {
			this.openFloatingFooter(shift);
		}
	}

	openFloatingFooter(shift) {
		this.editShiftsSelected = true;
		this.edit_shift = shift;
	}

	onCloseFooter() {
		this.editShiftsSelected = false;
		this.shifts.forEach(shift => {
			shift.selected = false;
		});
		this.shift_uuids.length = 0;
		this.applicantCount = 0;
		this.editing = false;
	}

	openEditShifts() {
		let noBankHol: boolean = true;
		if (this.edit_shift.uuids.length === 1) {
			this.openModal(noBankHol)
		}
		else {
			this.shifts.forEach(shift => {
				this.edit_shift.uuids.forEach((uuid, index, object) => {
					if (shift.uuid === uuid && shift.is_bank_holiday) {
						this.popNotif.createNotification('error', 'There has been a problem.', 'Bank holiday shifts cannot be batch edited.');
						shift.selected = false;
						object.splice(index, 1);
						if (object.length === 0) {
							this.onCloseFooter()
						}
					}
				});
			});
			this.openModal(noBankHol)
		}
	}

	openModal(a) {
		if (a) {
			if (this.edit_shift.uuids.length === 1) {
				this.shifts.forEach(shift => {
					if (shift.uuid === this.edit_shift.uuids[0]) {
						this.edit_shift = shift;
					}
				});
			}
			this.edit_shifts_modal = true;
			this.editShiftsSelected = false;
		}
	}

	selectAll() {
		this.edit_shift.uuids.length = 0;
		this.shifts.forEach(shift => {
			if (shift.now && !this.adminUser) {
				this.popNotif.createNotification('error', 'There has been a problem.', 'Shifts in progress cannot be edited.');
				shift.selected = false;
				return;
			}
			else {
				shift.selected = true;
				this.edit_shift.uuids.push(shift.uuid)
			}
		});
	}

	clickDelete() {
		this.getShiftsDetails();
	}

	deleteShifts() {
		this.floating_footer_loading = true;
		let shiftsToDelete = { 'shifts': this.edit_shift.uuids, 'remarks': this.deleteShiftsReason };
		this.shifts_service.deleteShifts(shiftsToDelete).then((result: any) => {
			this.event_tracker.trackEvent('delete_shift', result);
			this.popNotif.createNotification('success', 'Success', result.message);
			this.editShiftsClose();
			this.floating_footer_loading = false;
			this.onCloseFooter();
			this.closeDeleteModal();
		}).catch((err) => {
			// this.logger.error(err);
			this.popNotif.createNotification('error', 'There has been a problem.', err);
		});
	}

	checkForShiftsInProgress() {
		this.edit_shift.uuids.forEach((uuid, index) => {
			this.shifts.forEach(shift => {
				if (uuid === shift.uuid && shift.now) {
					shift.selected = false;
					this.edit_shift.uuids.splice(index, 1);
					this.popNotif.createNotification('error', 'There has been a problem.', 'Shifts in progress cannot be deleted.');
				}
			});
		});
	}

	async getShiftsDetails() {
		await this.checkForShiftsInProgress();
		let acceptedApplications = 0;
		let shifts = '';
		let shiftLength = this.edit_shift.uuids.length - 1;
		this.edit_shift.uuids.forEach((uuid, index) => {
			if (index != shiftLength) {
				shifts += uuid + ',';
			} else {
				shifts += uuid;
			}
		});
		await this.shifts_service.getShiftsDetails(shifts).then((result: any) => {
			result.forEach(shift => {
				acceptedApplications += shift.accepted_applications;
			});
			this.showAlert(acceptedApplications);
		}).catch((err) => {
			// this.logger.error(err);
		})
	}

	showAlert(acceptedApplicants) {
		this.applicantCount = acceptedApplicants;
		const shiftTotal = this.edit_shift.uuids.length;
		if (this.applicantCount === 0) {
			this.deleteShiftsBeta = true;
		} else {
			if (shiftTotal > 1) {
				this.alert.showConfirm("Delete Shifts", `You are about to delete ${shiftTotal} shifts. Some of the shifts you've selected may incur a cancellation charge. Are you sure you want to continue?`, 'Delete Shifts', 'Cancel').then(() => {
					this.deleteModalVisible = true;
				}).catch((e) => {
					this.onCloseFooter();
					return;
				});
			}
			else {
				this.alert.showConfirm("Delete Shift", `You are about to delete ${shiftTotal} shift, this may incur a cancellation charge. Are you sure you want to continue?`, 'Delete Shift', 'Cancel').then(() => {
					this.deleteModalVisible = true;
				}).catch((e) => {
					this.onCloseFooter();
					return;
				});
			}
		}
	}

	editShiftsClose(e?) {
		this.edit_shifts_modal = false;
		this.edit_shift.uuids.length = 0;
		this.editing = false;
		this.loadingAnimSkeleton(this.job);
		this.loading_anim_applicant = true;
		this.skipCaching = true;
		this.reset_page_data(true, true, true);
		this.loadDataAsync();
		this.shifts.forEach(shift => {
			shift.selected = false;
		});
	}

	checkPast(shift) {
		const end_date = new Date(shift.end_at);
		if (this.date_helper.compare(end_date, new Date()) == -1) {
			return false; //PAST
		} else {
			return true;  //FUTURE OR SAME
		}
	}

	copyJobLink(job) {
		this.jobs.getJobLink(job.uuid).then((result) => {
			if (result && result.link) {
				let jobLink = result.link;
				this.global_helpers.copy_clipboard(jobLink);
				this.popNotif.createNotification('success', 'Success', 'Link copied to clipboard.');
			} else {
				this.popNotif.createNotification('error', 'There has been a problem.', 'No link found.');
			}
		}).catch((err) => {
			if (err) {
				// this.logger.error(err);
				this.popNotif.createNotification('error', 'There has been a problem.', 'Please try again later.');
			}
		});
	}

	closeViewJob() {
		this.viewJobVisible = false;
		this.localHelpers.intercomBobbleVisibility('block');
	}

	closeShareJob() {
		this.share_job_visible = false;
		this.shared_job = null;
	}

	async shareJob(job) {
		this.shared_applicants.length = 0;
		this.share_job_visible = !this.share_job_visible;
		this.shared_job = job;
		this.page_workers = 1;
		await this.getSharedApplicants(this.page_workers);
	}

	async getSharedApplicants(page) {
		this.loading_shared_job = true;
		if (!this.shared_job || !this.shared_job.site) {
			return;
		}
		return await this.applications_service.workersWorked(this.search_worker, page, 10).then((res: any) => {
			this.shared_applicants = res['data'];
			this.total_pages_workers = res.metaData.last_page;
			this.loading_shared_job = false;
		}).catch(e => {
			this.loading_shared_job = false;
			this.popNotif.createNotification('error', 'There has been a problem.', e);
		})
	}

	async searchWorker() {
		this.shared_applicants.length = 0;
		this.page_workers = 1;
		await this.getSharedApplicants(this.page_workers);
	}

	changePage(e) {
		this.shared_applicants.length = 0;
		this.page_workers = e; //increment the page number
		this.getSharedApplicants(this.page_workers); //get the page number
	}

	changeApplicantPage(e) {
		this.mouseOverLeft = false;
		this.mouseOverRight = false;
		this.current_applications_page = e;
		this.applicants.length = 0;
		this.applications.length = 0;
		this.loadDataAsync(false);
	}

	sendJobToWOrker(event, worker) {

		event.loading_primary = true;
		const uuids = {
			uuids: [worker.applicant_uuid]
		};
		return this.jobs.shareJob(this.job.uuid, uuids).then((res) => {
			event.loading_primary = false;
			this.popNotif.createNotification('success', 'Success', 'Job shared successfully');
		}).catch(e => {
			event.loading_primary = false;
			this.popNotif.createNotification('error', 'There has been a problem.', 'Please try again later.');
		});
	}

	selectAllApplications(applicant) {
		this.onCloseFooter();
		if (!applicant.applications_selected) {
			this.selected_applications.length = 0;
			this.applicants.forEach(element => {
				element.applications_selected = false;
			});
			const all_applications = [];
			this.applications.forEach(application_row => {
				application_row.forEach(application => {
					all_applications.push(application);
				});
			});
			const found_application = all_applications.filter(application => {
				if (application && application.profile_uuid) {
					return (application.profile_uuid === applicant.uuid) && (application.status !== "cancelled") && (application.no_show == 0);
				}
			});
			if (found_application && found_application.length > 0) {
				this.selected_applications.length = 0;
				applicant.applications_selected = true;
				found_application.forEach(application => {
					this.selectApplication(application, applicant);
				});
			}
		} else {
			this.selected_applications.length = 0;
			applicant.applications_selected = false;
		}
	}

	changeShiftsPage(e) {
		this.loadingAnimSkeleton(this.job);
		this.loading_anim_applicant = true;
		this.reset_page_data(true, true, true);
		this.current_shift_page = e; //increment the page number
		this.loadDataAsync();
	}

	blacklistWorker(e) {
		this.blockWorkerObj.site = e.site;
		this.blockWorkerObj.uuid = e.uuid;
		this.modalVisible = true;
	}

	confirmBlockWorker() {
		this.applications_service.blacklistFromSite(this.blockWorkerObj, { 'reason': this.blockWorkerObj.reason }).then(() => {
			this.popNotif.createNotification('success', 'Success', 'Worker has been blocked from this site.')
			this.closeBlockWorkerModal();
			this.blockWorkerRefresh();
			this.applicantBlocked = true;
			this.getBlockedWorkers(this.job.site.uuid);
		}).catch((err) => {
			if (err.reason) {
				this.popNotif.createNotification('error', 'Sorry!', err.reason);
			}
			else {
				this.popNotif.createNotification('error', 'Sorry!', 'There has been a problem please try again');
			}
			// this.logger.error(err);
			this.closeBlockWorkerModal();
			this.blockWorkerRefresh();
		})
	}

	blockWorkerRefresh() {
		this.loadingAnimSkeleton(this.job);
		this.loading_anim_applicant = true;
		this.reset_page_data(true, true, true);
		this.loadDataAsync();
	}

	closeBlockWorkerModal() {
		this.modalVisible = false;
		this.blockWorkerObj.site = '';
		this.blockWorkerObj.uuid = '';
		this.blockWorkerObj.reason = '';
		this.blacklistReasons.forEach(elem => {
			elem.selected = false;
		});
	}

	closeDeleteModal() {
		this.deleteShiftsReason = '';
		this.deleteModalVisible = false;
		this.onCloseFooter();
		this.jobProvider.deleteReasons.map(elem => {
			elem.selected = false;
		});
	}

	selectBlacklistReason(reason) {
		if (reason === 'Other') {
			this.blockWorkerObj.reason = '';
		}
		else {
			this.blockWorkerObj.reason = reason;
		}
		this.blacklistReasons.forEach(elem => {
			if (reason === elem.reason) {
				elem.selected = true;
			}
			else {
				elem.selected = false;
			}
		});
	}

	selectDeleteReason(reason) {
		if (reason === 'Other') {
			this.deleteShiftsReason = '';
		}
		else {
			this.deleteShiftsReason = reason;
		}
		this.jobProvider.deleteReasons.map(elem => {
			if (reason === elem.reason) {
				elem.selected = true;
			}
			else {
				elem.selected = false;
			}
		});
	}

	viewBlocked() {
		this.blockedWorkers = true;
	}

	unblockWorker(event, worker, i) {
		event.loading_primary = true;
		this.applications_service.removeBlacklistFromSite(worker.jobProfileUuid, this.job.site.uuid).then((result) => {
			this.popNotif.createNotification('success', 'Success', 'Worker has been unblocked from this site.')
			event.loading_primary = false;
			this.workersBlocked.splice(i, 1);
			if (this.workersBlocked.length === 0) {
				this.blockedWorkers = false;
			}
		}).catch((err) => {
			console.log(err)
			this.popNotif.createNotification('error', 'There has been a problem', err.message);
			event.loading_primary = false;
			this.closeBlocked();
		});
	}

	async expirePopUpAction(application) {
		if (application.status === 'expired') {
			const found = this.applicants.filter(applicant => {
				return applicant.uuid === application.profile_uuid;
			});
			if (found && found.length > 0) {
				const userUuid = found[0].user.uuid;
				await this.sendJobToWOrker(this.expirePopUpLoader, { applicant_uuid: userUuid });
				application.expirePopOverVisible = false;
			}
		} else {
			if (application && application.uuid) {
				this.expirePopUpLoader.loading_primary = true;
				const applications = { applications: [application.uuid] };
				await this.approveApplications(applications);
				setTimeout(() => {
					this.expirePopUpLoader.loading_primary = false;
					application.expirePopOverVisible = false;
				}, 3000);

			}
		}


	}

	closeDeleteShiftsBeta() {
		this.deleteShiftsBeta = false;
		this.onCloseFooter();
	}

	escalateShifts() {
		this.escalateLoadingBtn = true;
		const shiftsUuids = {
			"shift_uuids": this.edit_shift.uuids
		};
		this.shifts_service.escalateShifts(shiftsUuids).then(() => {
			this.escalateLoadingBtn = false;
			this.closeDeleteShiftsBeta();
			this.alert.showSuccess('', `The team have been made aware and will start working on this now.`, 'OK',);
		}).catch(err => {
			this.escalateLoadingBtn = false;
			this.popNotif.createNotification('error', 'There has been a problem.', err);
		})

	}
	deleteShiftsBetaContinue() {
		this.deleteShiftsBeta = false;
		this.deleteModalVisible = true;
	}

	applicantJobCompliantStyle(applicant) {
		const { work_job_compliant } = applicant?.worker_status?.compliant_statuses;
		if (!work_job_compliant) {
			if (this.adminUser) {
				return "held-at-applicant";
				// return "applicant-hide";
			} else {
				return "applicant-hide";
			}
		}
	}
	applicationsHeldAtStyle(application, last) {
		let ngClassName = last ? 'no-margin-right ' : '';
		const { work_job_compliant } = application?.applicant?.worker_status?.compliant_statuses;
		if (!work_job_compliant) {
			if (this.adminUser) {
				ngClassName += "application-row-held-at";
				// return 'application-row-hide';
			} else {
				ngClassName += 'application-row-hide';
			}
		}
		return ngClassName.trim();
	}
	goToAdmin(applicant) {
		const mobile = applicant.user?.mobile_number;
		this.goToAdminWorkers.emit(mobile);
	}

	checkWorkerStatusIcon(applicant, tag) {
		if (tag === 'rtw_status' || tag === 'id_status') {
			if (applicant.worker_status.compliant_statuses[tag] === 'approved') {
				return 'assets/icons/check-circle.svg';
			}
			if (applicant.worker_status.compliant_statuses[tag] === 'pending') {
				return 'assets/icons/ic-clock.svg';
			}
			if (applicant.worker_status.compliant_statuses[tag] === 'rejected' || applicant.worker_status.compliant_statuses[tag] === 'none') {
				return 'assets/icons/alert-red.svg';
			}
		}
		if (tag === 'compliance_status') {
			if (applicant.worker_status.compliant_statuses[tag] === 'fully_compliant' || applicant.worker_status.compliant_statuses[tag] === 'partially_compliant') {
				return 'assets/icons/check-circle.svg';
			}
			if (applicant.worker_status.compliant_statuses[tag] === 'none' || applicant.worker_status.compliant_statuses[tag] === 'not_compliant') {
				return 'assets/icons/alert-red.svg';
			}
		}

		if (tag === 'has_tax' || tag === 'has_bank') {
			return applicant.worker_status.employee_statuses[tag] ? 'assets/icons/check-circle.svg' : 'assets/icons/alert-red.svg';
		}
	}

}
