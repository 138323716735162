<div (click)="togglePopover()" class="wrapper noselect">
    <div nzPopoverPlacement="bottom" nz-popover [(nzPopoverVisible)]="show_list" nzPopoverTrigger="null"
        [nzPopoverContent]="contentDropTemplate" [ngClass]="!border_right ? 'no-right-border': '' "
        [style.width]="element_width" class="drop-label cursor-pointer">
        <span [ngClass]="title_uppercase ? 'title-uppercase': ''">{{value}}</span>
        <div *ngIf="data && data.length > 1">
            <img *ngIf="!show_list" src="assets/icons/arrow-down.svg" />
            <img *ngIf="show_list" src="assets/icons/arrow-up.svg" />
        </div>

    </div>
</div>
<ng-template #contentDropTemplate>
    <div [style.width]="element_width" class="drop-down-items b-result-box">
        <ul>
            <li class="list-wrapper" *ngFor="let element of data" (click)="setValue(element)">
                <span class="b-Eh4">{{element?.title}}</span>
                <img *ngIf="element.img" src="{{element.img}}" />
            </li>
        </ul>
    </div>
</ng-template>