import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AlertService } from "@broadstone/alert";
import { PopNotificationService } from "@broadstone/pop-notification-service";
import { EmployeesService } from "src/services/public_api";

@Component({
	selector: "app-worker-statuses",
	templateUrl: "./worker-statuses.component.html",
	styleUrls: ["./worker-statuses.component.scss"],
})
export class WorkerStatusesComponent {
	modalContent: any; // @todo added for build to pass - update type and default value
	@Input() statusModalVisible: boolean = false;
	@Input() worker: any;
	@Output() statusModalVisibleChange: EventEmitter<any> = new EventEmitter();
	@Output() refreshWorkers: EventEmitter<any> = new EventEmitter();

	workStatusesData: any = [
		{
			title: "RTW",
			subtitle: "",
			statusParam: 0,
			effectiveDate: '',
			valueName: "checkRtwStatus",
			selected: null,
			dropDownData: [
				{ title: "None", value: "none" },
				{ title: "Approved", value: "approved" },
				{ title: "Pending", value: "pending" },
				{ title: "Rejected", value: "rejected" },
			],
		},
		{
			title: "ID Check",
			subtitle: "",
			statusParam: 0,
			effectiveDate: '',
			valueName: "checkIdStatus",
			selected: null,
			dropDownData: [
				{ title: "None", value: "none" },
				{ title: "Approved", value: "approved" },
				{ title: "Pending", value: "pending" },
				{ title: "Rejected", value: "rejected" },
			],
		},
		{
			title: "Clearance",
			subtitle: "BS7858 clearance",
			statusParam: 0,
			effectiveDate: '',
			valueName: "complianceStatus",
			selected: null,
			dropDownData: [
				{ title: "None", value: "none" },
				{ title: "Not compliant", value: "not_compliant" },
				{ title: "Limited Screening", value: "partially_compliant" },
				{ title: "Fully Compliant", value: "fully_compliant" },
			],
		},
	];
	renderDone: boolean = false;
	constructor(
		private employeesService: EmployeesService,
		private alert: AlertService,
		private popNotif: PopNotificationService) { }

	ngOnChanges() {
		if (this.worker) {
			this.mapEffectiveDate();
			this.removeClearedStatus();
			this.mapDropDownSelection();
			this.renderDone = true;
		}
	}

	mapEffectiveDate() {
		this.workStatusesData.map((element) => {
			if (element.valueName === 'checkRtwStatus') {
				element.effectiveDate = this.worker.statuses.rtwEffectiveDate;
			}
			if (element.valueName === 'checkIdStatus') {
				element.effectiveDate = this.worker.statuses.idEffectiveDate;
			}
			if (element.valueName === 'complianceStatus') {
				element.effectiveDate = this.worker.statuses.complianceEffectiveFrom;
			}
		});
	}

	removeClearedStatus() {
		if (!this.worker.details.registeredSectors.includes('Security')) {
			this.workStatusesData.findIndex((element) => element.valueName === 'complianceStatus');
			this.workStatusesData.splice(2, 1);
		}
	}

	mapDropDownSelection() {
		this.workStatusesData.map((element) => {
			const status = this.worker.statuses[element.valueName];
			if (!status) return;
			const index = element.dropDownData.findIndex(
				(item) => item.value === status
			);
			element.statusParam = index;
		});
	}

	ngOnInit(): void { }

	closeStatusModal() {
		this.statusModalVisible = false;
		this.statusModalVisibleChange.emit(this.statusModalVisible);
	}

	confirm() {
		this.alert.showConfirm("Are you sure?", "You are updating a worker’s compliance status. If this includes changes to their Clearance Status, you will reset their eligibility period to a full 90 days and immediately affect their ability to work. Do you wish to proceed with these changes?", "Yes").then(() => {
			this.submit();
		});
	}

	submit() {
		const updateObj = {
			worker_statuses: {
				"id_status": this.getSelectedValue("checkIdStatus"),
				"right_to_work": this.getSelectedValue("checkRtwStatus"),
			}
		};

		if (this.worker.details.registeredSectors.includes('Security')) {
			updateObj.worker_statuses['compliance_status'] = this.getSelectedValue("complianceStatus");
		}

		this.employeesService.updateWorker(updateObj, this.worker.details.userUuid).then((result) => {
			this.popNotif.createNotification('success', 'Success', 'Worker status updated.');
			this.refreshWorkers.emit();
			this.closeStatusModal();
		}).catch((err) => {
			this.popNotif.createNotification('error', 'There has been a problem.', err);
		});
	}

	getSelectedValue(item: 'checkRtwStatus' | 'checkIdStatus' | 'complianceStatus') {
		const found = this.workStatusesData.find((element) => element.valueName === item);
		return found.selected;
	}

	topUpColors() {
		if (this.worker.statuses?.daysToTopUp >= 31 && this.worker.statuses?.daysToTopUp < 61) {
			return 'warning';
		} else if (this.worker.statuses?.daysToTopUp >= 61) {
			return 'green';
		} else {
			return 'danger';
		}
	}

	selectSubmissionStatus(item, selected) {
		if (item.selected !== selected.value) {
			item.selected = selected.value;
			return;
		}
	}

}
